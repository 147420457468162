html, body {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400; }

b {
  font-weight: bold; }

.meta_info {
  background-color: #db0011;
  color: white;
  box-sizing: border-box;
  padding: 10px 0px; }
  .meta_info span {
    display: inline-block; }

.head {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 2; }

.my_custom_nav {
  box-sizing: border-box;
  padding: 10px 0px;
  background-color: transparent;
  margin-top: 3rem; }
  .my_custom_nav .container .content {
    display: flex;
    justify-content: space-between; }
  .my_custom_nav .logo {
    position: relative;
    width: 100px;
    height: 100px; }
    .my_custom_nav .logo img {
      width: 100%;
      height: 100%; }
  .my_custom_nav ul {
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: center; }
    .my_custom_nav ul li {
      margin-left: 20px; }
      .my_custom_nav ul li a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1.2rem; }
      .my_custom_nav ul li:hover a {
        color: #ffffff;
        text-decoration: underline; }

.container-fluid {
  padding: 0px; }

.hamburger {
  color: #ffffff;
  font-size: 1.5rem;
  display: none;
  cursor: pointer;
  position: relative;
  z-index: 1; }

h1 {
  color: white;
  font-weight: 900;
  margin: 30px 0px;
  font-size: 3rem; }

.large_icon_wrapper {
  display: flex;
  justify-content: center; }
  .large_icon_wrapper .large_icon {
    width: 400px; }
    .large_icon_wrapper .large_icon svg {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1; }

.wallet svg, .exchange svg {
  width: 32px !important;
  height: 32px !important; }

.wallet img, .exchange img {
  width: 60%;
  position: relative;
  z-index: 2; }

.wallet .background, .exchange .background {
  background-color: #ffc057 !important; }

svg#cogs .st2 {
  animation: rotate 20s linear infinite;
  transform-origin: center;
  transform-box: fill-box; }

.container-fluid.forms {
  background-color: white;
  padding: 100px 0px;
  padding-bottom: 0px;
  box-sizing: border-box; }
  .container-fluid.forms .col-sm-6.newsletter {
    position: relative; }
    .container-fluid.forms .col-sm-6.newsletter img {
      width: 100%; }
  .container-fluid.forms h1 {
    color: #333; }
  .container-fluid.forms p {
    color: #333; }
  .container-fluid.forms label {
    display: none; }
  .container-fluid.forms input, .container-fluid.forms select, .container-fluid.forms textarea {
    -webkit-appearance: none;
    border: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: inset 0 2px 5px 0px rgba(1, 1, 1, 0.1);
    border: 1px solid rgba(1, 1, 1, 0.1);
    box-sizing: border-box;
    background-color: white;
    color: #333; }
    .container-fluid.forms input::placeholder, .container-fluid.forms select::placeholder, .container-fluid.forms textarea::placeholder {
      color: #cccccc; }
    .container-fluid.forms input:focus, .container-fluid.forms select:focus, .container-fluid.forms textarea:focus {
      outline: none; }
    .container-fluid.forms input[type=submit], .container-fluid.forms select[type=submit], .container-fluid.forms textarea[type=submit] {
      cursor: pointer;
      width: auto;
      background-color: #db0011;
      color: white;
      padding: 10px 30px;
      transition: all 300ms ease;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
      .container-fluid.forms input[type=submit]:hover, .container-fluid.forms select[type=submit]:hover, .container-fluid.forms textarea[type=submit]:hover {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        transform: translateY(-1px); }
  .container-fluid.forms select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 15px), calc(100% - 15px) calc(1em + 15px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat; }

.container-fluid.wallets {
  padding: 100px 0px;
  position: relative;
  background-image: url("/assets/wallets_edited.png");
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed; }
  .container-fluid.wallets .cta {
    background-color: #db0011; }
  .container-fluid.wallets svg {
    fill: #db0011; }

.container-fluid.downloads {
  padding: 100px 0px;
  background-color: #db0011; }
  .container-fluid.downloads .row {
    margin-top: 100px; }
  .container-fluid.downloads h1 {
    text-align: center; }
  .container-fluid.downloads .download {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
    .container-fluid.downloads .download h2 {
      font-weight: 900;
      color: #db0011;
      text-align: center; }
    .container-fluid.downloads .download p {
      font-weight: 100;
      color: #db0011; }
    .container-fluid.downloads .download .download_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(-60px); }
  .container-fluid.downloads .icon {
    width: 120px;
    height: 120px; }
    .container-fluid.downloads .icon svg {
      fill: #db0011; }
    .container-fluid.downloads .icon img {
      position: relative;
      z-index: 2;
      width: 50%; }
    .container-fluid.downloads .icon:after {
      background-color: white;
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
  .container-fluid.downloads .cta {
    background-color: #db0011;
    color: white;
    margin-top: 40px; }

.container-fluid.specifications {
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white; }
  .container-fluid.specifications h1 {
    color: #333; }
  .container-fluid.specifications li {
    color: #333;
    margin-bottom: 10px; }

.container-fluid.decentralized {
  background-image: url("/assets/31cc6ee7de8d31d3689c.png");
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #db0011;
  text-align: right; }
  .container-fluid.decentralized p {
    color: white;
    margin-bottom: 30px; }
  .container-fluid.decentralized .cta {
    color: #db0011;
    background-color: white; }

.container-fluid.blocktime {
  box-sizing: border-box;
  padding-top: 140px;
  padding-bottom: 100px; }
  .container-fluid.blocktime p {
    color: white;
    margin-bottom: 30px; }
  .container-fluid.blocktime .cta {
    color: #ffffff; }

.container-fluid.exchanges {
  background-color: #db0011;
  padding: 100px 0px; }
  .container-fluid.exchanges .coming_soon .icon .background {
    background-color: white;
    opacity: 1; }
  .container-fluid.exchanges .coming_soon .icon svg {
    fill: #db0011; }
  .container-fluid.exchanges .coming_soon .icon img {
    opacity: 0.5; }
  .container-fluid.exchanges .coming_soon h2, .container-fluid.exchanges .coming_soon p {
    color: white;
    opacity: 0.5; }
  .container-fluid.exchanges .icon {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
    .container-fluid.exchanges .icon svg {
      fill: #db0011; }
  .container-fluid.exchanges h1 {
    text-align: center; }
  .container-fluid.exchanges .row {
    display: flex;
    justify-content: center; }
    .container-fluid.exchanges .row .exchange {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffc057;
      margin-bottom: 60px; }
      .container-fluid.exchanges .row .exchange h2 {
        font-size: 1.5rem;
        margin-bottom: 20px; }
      .container-fluid.exchanges .row .exchange .cta {
        color: #ffffff; }

.container-fluid.introduction {
  position: relative;
  box-sizing: border-box;
  padding: 240px 0px;
  padding-bottom: 140px;
  min-height: 450px;
  background-color: #db0011;
  color: white; }
  .container-fluid.introduction .cta {
    color: #db0011; }
  .container-fluid.introduction .container {
    position: relative;
    z-index: 1; }
  .container-fluid.introduction p {
    margin: 20px 0px;
    color: white; }
  .container-fluid.introduction .text {
    z-index: 1; }
  .container-fluid.introduction .image img {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    transform: scale(1.5);
    margin-top: 40px; }
  .container-fluid.introduction .image .iphone {
    position: absolute;
    left: 50%;
    margin-left: -25%;
    width: 300px;
    height: 500px;
    background-color: #db0011;
    border-radius: 30px;
    box-sizing: border-box;
    border: 10px solid white;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
    .container-fluid.introduction .image .iphone .front-cam {
      position: absolute;
      top: 10px;
      left: 50%;
      margin-left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: white; }
    .container-fluid.introduction .image .iphone .speaker {
      position: absolute;
      top: 30px;
      left: 50%;
      margin-left: -35px;
      width: 70px;
      height: 4px;
      background-color: white;
      border-radius: 4px; }
    .container-fluid.introduction .image .iphone .screen {
      position: absolute;
      width: 90%;
      left: 5%;
      height: 360px;
      top: 50px;
      background-color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      padding-top: 20px; }
      .container-fluid.introduction .image .iphone .screen .bcf {
        width: 80px;
        position: relative; }
        .container-fluid.introduction .image .iphone .screen .bcf img {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1; }
        .container-fluid.introduction .image .iphone .screen .bcf:after {
          content: " ";
          width: 100%;
          height: 10px;
          display: block;
          background-color: #870202;
          position: absolute;
          top: 100%;
          margin-top: -5px;
          border-radius: 50%; }
      .container-fluid.introduction .image .iphone .screen .text {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #db0011;
        margin-top: 20px; }
        .container-fluid.introduction .image .iphone .screen .text h2 {
          margin: 0px;
          color: #db0011; }
        .container-fluid.introduction .image .iphone .screen .text p {
          color: #db0011; }
        .container-fluid.introduction .image .iphone .screen .text .cta {
          color: white;
          background-color: #db0011; }

.cta {
  box-sizing: border-box;
  padding: 10px 30px;
  display: inline-block;
  background-color: white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 300ms ease;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0px); }
  .cta:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px); }
  .cta a {
    color: #ffffff;
    text-decoration: none; }

.video-background {
  background: #db0011;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.3;
  overflow: hidden; }

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: scale(1.1); }

.wallets h1 {
  text-align: center; }

.wallets .wallets_wrapper .row {
  width: 100%;
  margin: 0px;
  display: flex;
  justify-content: center;
  margin-top: 40px; }

.wallets .wallets_wrapper .wallet {
  position: relative;
  width: 100%;
  flex-basis: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white; }
  .wallets .wallets_wrapper .wallet.unreleased {
    color: #dddddd; }
    .wallets .wallets_wrapper .wallet.unreleased .icon .background {
      background-color: #dddddd; }
    .wallets .wallets_wrapper .wallet.unreleased .icon svg {
      fill: #ffffff; }
    .wallets .wallets_wrapper .wallet.unreleased:after {
      background-color: #dddddd; }
  .wallets .wallets_wrapper .wallet.coming_soon {
    color: #e96670; }
    .wallets .wallets_wrapper .wallet.coming_soon .icon .background {
      background-color: #e96670; }
    .wallets .wallets_wrapper .wallet.coming_soon .icon svg {
      fill: #ffffff; }
    .wallets .wallets_wrapper .wallet.coming_soon .icon img {
      opacity: 0.5; }
    .wallets .wallets_wrapper .wallet.coming_soon .icon:after {
      background-color: #e96670;
      animation: radiate 3s linear infinite; }
    .wallets .wallets_wrapper .wallet.coming_soon:after {
      background-color: #e96670; }
  .wallets .wallets_wrapper .wallet.new .icon .background {
    position: absolute; }
    .wallets .wallets_wrapper .wallet.new .icon .background:after {
      content: "new";
      font-size: 10px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      right: -5px;
      width: 24px;
      height: 24px;
      background-color: #db0011;
      border-radius: 50%;
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
  .wallets .wallets_wrapper .wallet .cta {
    color: white; }
  .wallets .wallets_wrapper .wallet h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    text-align: center; }

.icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 10px; }
  .icon .background, .icon:after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0px;
    left: 0px;
    border-radius: 50%; }
  .icon .background {
    z-index: 1; }
  .icon:after {
    content: " "; }
  .icon svg {
    display: block;
    fill: #ffffff;
    width: 50%;
    height: 50%;
    position: relative;
    z-index: 1; }

.buttons {
  margin-bottom: 40px; }

.foot {
  position: relative;
  display: block;
  width: 100%;
  background: linear-gradient(white, #db0011);
  padding: 100px 0px; }
  .foot .buttons {
    text-align: center; }
    .foot .buttons a {
      color: #db0011; }
  .foot p {
    text-align: center;
    color: white; }
  .foot .group {
    margin-bottom: 30px; }
    .foot .group.social-medias {
      margin-top: 40px;
      display: flex;
      justify-content: center; }
      .foot .group.social-medias .icon {
        margin: 0px 10px;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
        .foot .group.social-medias .icon path {
          fill: #db0011; }
  .foot .globe {
    width: 100%; }
    .foot .globe .st1 {
      opacity: 1; }
  .foot h1 {
    text-align: center; }

.large_icon.missile svg circle, .large_icon.specifications svg circle {
  fill: #db0011; }

.large_icon.missile svg .st2, .large_icon.specifications svg .st2 {
  fill: #ffc057; }

.large_icon.missile svg .st1, .large_icon.specifications svg .st1 {
  fill: rgba(1, 1, 1, 0.1); }

.large_icon.decentralized svg circle {
  fill: #ffc057; }

.large_icon.decentralized svg .st1 {
  fill: white; }

.large_icon.decentralized svg .st2 {
  fill: #db0011; }

.faq.wrapper {
  overflow: hidden; }
  .faq.wrapper .container-fluid.questions {
    padding: 100px 0px;
    background-color: white; }
  .faq.wrapper .container-fluid.introduction {
    background-color: #db0011; }
    .faq.wrapper .container-fluid.introduction .row .image {
      position: absolute;
      right: 0px;
      top: -200px; }
    .faq.wrapper .container-fluid.introduction h1 {
      color: white; }

.ui-icon, .ui-button:active .ui-icon {
  display: none; }

.ui-accordion .ui-accordion-header {
  font-size: 2rem;
  background-color: #e96670;
  color: white;
  border: 0px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  margin-top: 20px;
  padding: .5em .5em .5em .7em;
  padding-top: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.7em;
  margin-bottom: 0px;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }
  .ui-accordion .ui-accordion-header:first-child {
    margin-top: 0px; }
  .ui-accordion .ui-accordion-header:focus {
    outline: none; }
  .ui-accordion .ui-accordion-header.ui-state-active {
    background-color: #db0011;
    color: white; }

.ui-accordion-content {
  color: white; }

.contact.form.wrapper {
  text-align: center; }

.copyright {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center; }

.inverted h1, .inverted.container-fluid.blocktime p, .inverted h2 {
  color: #333; }

.inverted .coming_soon h2, .inverted .coming_soon p {
  color: #777; }

.inverted .unreleased h2, .inverted .unreleased p {
  color: #dddddd; }

.inverted .cta {
  background-color: #db0011; }

.inverted .icon .background {
  background-color: #db0011; }

.inverted .icon svg {
  fill: white; }

.exchanges h2 {
  color: white; }

.exchanges .cta {
  color: #db0011 !important; }

.foot svg circle {
  fill: #db0011; }

.foot svg .st1 {
  fill: #83000a;
  opacity: 0.3 !important; }

.foot svg .st2 {
  fill: white; }

.ui-accordion .ui-accordion-content {
  background-color: #dddddd;
  color: #db0011;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1em 2.2em;
  padding-top: 1em;
  padding-right: 2.2em;
  padding-bottom: 1em;
  padding-left: 2.2em; }

.contact_us h1, .newsletter h1 {
  font-size: 36px; }

.forms p img {
  max-height: 72px; }

.particles {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url("/assets/digital-blue-world-map-loop-footage-012189476_prevstill.png");
  background-size: cover;
  background-position: 50% 50%; }

@media (max-width: 991px) {
  .container-fluid.introduction .image {
    margin-top: 50px;
    display: flex;
    justify-content: center; }
    .container-fluid.introduction .image .iphone {
      position: relative;
      left: auto;
      margin: 0px; } }

@media (max-width: 767px) {
  .faq.wrapper .container-fluid.introduction .row .image {
    display: none; }
  .container-fluid.downloads .col-sm-4 {
    margin-bottom: 100px; }
    .container-fluid.downloads .col-sm-4:last-child {
      margin-bottom: 0px; }
  .video-background {
    background-image: url("/assets/matrix.png");
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat; }
    .video-background .video-foreground {
      display: none; }
  .wallets .wallets_wrapper {
    flex-direction: column; }
    .wallets .wallets_wrapper .wallet {
      width: 100%;
      margin-bottom: 50px; }
      .wallets .wallets_wrapper .wallet:after {
        display: none; } }

@media (max-width: 600px) {
  .meta_info, .my_custom_nav {
    padding: 10px 10px; }
  .hamburger {
    display: flex;
    align-items: center; }
  .my_custom_nav {
    z-index: 1;
    position: relative; }
    .my_custom_nav ul {
      margin: 0px;
      padding: 0px;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 0%;
      flex-direction: column;
      background-color: #db0011;
      transition: all 300ms ease;
      overflow: hidden;
      align-items: center;
      justify-content: center; }
      .my_custom_nav ul.expanded {
        height: 200%; }
      .my_custom_nav ul li {
        margin: 0px; } }

@media (max-width: 400px) {
  h1 {
    font-size: 2rem; }
  .buttons a {
    display: block;
    margin-bottom: 10px; } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes radiate {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1.5);
    opacity: 0; } }

.homepage.wrapper {
  overflow: hidden; }

* {
  box-sizing: border-box; }

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.ticker-wrap {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 3rem;
  background-color: #db0011;
  padding-left: 100%;
  box-sizing: content-box;
  z-index: 2;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 30s; }
    .ticker-wrap .ticker__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 1.2rem;
      color: white;
      font-weight: 100; }

span.bold {
  font-weight: 900; }
